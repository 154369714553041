import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import createMarkup from '../helpers/createMarkup';
import Section from '../components/common/Section';
import IconGroup from '../components/common/icon/IconGroup';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blogPostList, iconList, menuList1, menuList2 } from '../data/footer';
import { animateScroll } from 'react-scroll';
import { version } from '../config';

const FooterTitle = ({ children }) => <h5 className="text-uppercase text-white opacity-85 mb-3">{children}</h5>;

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="text-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-0 mb-0">
          <Link className="text-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read {blog.star && <span dangerouslySetInnerHTML={createMarkup('&starf;')} />}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };


const FooterStandard = () => {
  return (
    <Fragment>

      <Section bg="dark" className="pt-6 pb-4">
        <div className="position-absolute btn-back-to-top cursor-pointer bg-dark" onClick={animateScroll.scrollToTop}>
          <FontAwesomeIcon icon="chevron-up" transform="rotate-45" className="text-600" />
        </div>
        <Row>
          <Col lg={4}>
            <FooterTitle>我們的願景</FooterTitle>
            <p className="text-800">
              <b style={{fontSize:'25px'}}>快速實現你的每一個交易想法!</b>
            </p>
          </Col>
          <Col className="pl-lg-6 pl-xl-8">
            <Row className="mt-5 mt-lg-0">
            </Row>
          </Col>
        </Row>
      </Section>

      <section className=" bg-dark py-0 fs--1">
        <div className="container py-3">
          <Row className="">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                免責聲明:<br/>
                您已同意，使用本服務由您自行承擔風險。在法律允許的最大限度內，stockscan.tw及其管理人員、董事、員工和代理人全部免予承擔所有明示或默示的關於服務和您使用服務的保證。在法律允許的最大限度內，stockscan.tw 免予承擔所有有關本網站或其鏈結的網站內容的準確性或完整性的保證、條件、條款和陳述之擔保責任，並且不承擔下列責任<br/>
                stockscan.tw 網站内之數據內容應由數據演算法以及内容提供者負責，您不應作為投資依據，亦不代表stockscan.tw立場。若因內容提供者資料錯誤造成投資損失，本公司不負任何法律相關責任。<br/>
                stockscan.tw 網站明確聲明對所有資料的正確性、適當性或完整性不負任何責任，且對於資料中的任何錯誤、遺漏或其他瑕疵、資料的延遲或中斷、或因信任資料而採取的任何行動均不負責任。 stockscan.tw 將不會對任何因使用在此提供的資料而造成的損失負責。<br/>
              </p>
            </Col>
            <Col xs={12} sm="auto">
            </Col>
          </Row>
        </div>
      </section>

      <section className=" bg-dark py-0 text-center fs--1 pb-5">
        {/*<hr className="my-0 border-600 opacity-25" />*/}
        <div className="container py-3">
          <Row className="justify-content-between">
            <Col xs={12} sm="auto">
              <p className="mb-0 text-600">
                <span className="d-none d-sm-inline-block"></span>
                <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
                <a
                  className="text-white opacity-85"
                  href="https://stockscan.tw"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  stockscan.tw
                </a>
              </p>
            </Col>
            <Col xs={12} sm="auto">
              {/*<p className="mb-0 text-600">v{version}</p>*/}
            </Col>
          </Row>
        </div>
      </section>

    </Fragment>
  );
};

export default FooterStandard;
