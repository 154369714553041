import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import NavbarStandard from './navbar/NavbarStandard';
import Banner from './Banner';
import Partners from './Partners';
import Processes from '../components/landing/Processes';
import Services from './Services';
import Testimonial from '../components/landing/Testimonial';
import Cta from '../components/landing/Cta';
import FooterStandard from './FooterStandard';
//import Watchlist from "./Watchlist";
import WatchlistByIndustry from "./WatchlistByIndustry";

const Landing = ({ location, match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // {/*<NavbarStandard location={location} match={match} />*/}

    /*
    <Fragment>
      <NavbarStandard location={location} match={match} />
      <Banner />
      <Partners />
      <Processes />
      <Services />
      <Testimonial />
      <Cta />
      <FooterStandard />
    </Fragment>
    */

    //

  return (
      <Fragment>
          <NavbarStandard location={location} match={match} />
          <Banner />
          <Partners />
          {/*<Processes />*/}
          <Services />
          {/*<Testimonial />*/}
          {/*<Cta />*/}
          <FooterStandard />
    </Fragment>
  );
};

Landing.propTypes = { location: PropTypes.object.isRequired };

export default Landing;
