import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';

import AuthCardLayout from './AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <Fragment>
        </Fragment>
      }
    >
      <h3>客戶登入</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
