import React from 'react';
import { Row, Col } from 'reactstrap';
import className from 'classnames';
//import serviceList from '../../data/feature/serviceList';
import Section from '../components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';


const Services = () => {


    let serviceList = [
        {
            media: { icon: ['far', 'hdd'], color: 'info', className: 'fs-5' },
            title: '量身訂製解決方案',
            iconName: 'laptop',
            description:
                "從概念到實作全方位服務",
            children: [
                '市場數據資料庫建制',
                '訊號產生程式開發',
                '市場信息每日更新維護',
                '第三方圖形工具整合',
            ]
        },
        {
            media: { icon: ['fab', 'node-js'], color: 'success', className: 'fs-5' },
            title: '程式開發以及工具整合',
            description:
                "",
            children: [
                '快速實現分析工具開發',
                '客戶擁有訊號生成程式的所有權',
                '高可靠度AWS及Google Cloud雲端運營',
            ]
        },
        {
            media: { icon: ['far', 'file-code'], color: 'primary', className: 'fs-5' },
            title: '股票觀察名單',
            iconName: 'file-code',
            description:
                '依照軟件需求產生不同格式的股票名單',
            children: [
                'CSV',
                'TradingView',
                'MultiChart',
            ]
        }
    ];

    return (
        <Section bg="light" className="text-center">
            <SectionHeader
                title="我們提供的服務"
                subtitle="詳細項目費用報價請與我們聯絡"
            />
            <Row className="mt-6">
                {serviceList.map((service, index) => (
                    <Col lg={4} className={className({ 'mt-6 mt-lg-0': index > 0 })} key={index}>
                        <CardService {...service} />
                    </Col>
                ))}
            </Row>
        </Section>
    )
};

export default Services;
