import { Table } from 'reactstrap';
import React, { Fragment } from 'react';


const TickerTable = (props) => {
    const {data, rankType, binType} = props

    /*
    var heading = "市值範圍";
    if (rankType === "by_industry") {
        heading = "產業";
    }
     */

    function buildTableRows() {
        var rows = [];
        var count = 0
        let tickers;

        tickers = data[rankType];
        /*
        if (binType === "by_range") {
            tickers = data["by_range"];
        } else if (binType === "by_industry") {
            tickers = data["by_industry"];
        } else if (binType === "by_volume") {
            tickers = data["by_volume"];
        }
        */

        for (const idx in tickers) {
            let row = tickers[idx];
            if (rankType === "by_industry" && row["INDUSTRY_CODE"] !== parseInt(binType)) {
                continue
            }
            if (rankType === "by_volume" && row["BIN"] !== binType) {
                continue
            }
            if (rankType === "by_range" && row["BIN"] !== binType) {
                continue
            }

            rows.push(
                <tr key={"key_"+count}>
                    <th scope="row">{count}</th>
                    <td><a href={"https://www.wantgoo.com/stock/" + row['TICKER'] + "/technical-chart"} target="_blank" rel="noreferrer">{row["TICKER"]}</a></td>
                    <td>{row["NAME"]}</td>
                    <td>{row["VOLA"]}</td>
                    <td>{row["VOLA_10"]}</td>
                    <td>{row["VOLA_20"]}</td>
                    <td>{row["VOLUME_MEDIAN_20"]}</td>
                    <td>{row["RANGE_2D"]}</td>
                    <td>{row["RANGE_MEDIAN_10D"]}</td>
                    <td>{row["RANGE_Z_10D"]}</td>
                    <td>{row["BIN"]}</td>
                    <td>{row["INDUSTRY"]}</td>
                    <td>{row["SEC_VOLA_10"]}</td>
                    <td>{row["SEC_VOLA_20"]}</td>
                    <td><a href={"https://www.wantgoo.com/stock/" + row['TICKER'] + "/technical-chart"} target="_blank" rel="noreferrer">{row["TICKER"]}</a></td>
                </tr>)
            count = count + 1;
        }
        return (
            <Fragment>
                {rows}
            </Fragment>
        );
    }
    var tableRows = buildTableRows();

    return (
        <Table hover striped variant="light" responsive>
            <thead className="thead-light">
            <tr>
                <th>#</th>
                <th>代號</th>
                <th>名稱</th>
                <th>VOLA</th>
                <th>VOLA10</th>
                <th>VOLA20</th>
                <th>VOLUME</th>
                <th>區間</th>
                <th>區間10D</th>
                <th>區間Z</th>
                <th>市值範圍</th>
                <th>產業</th>
                <th>產業V10</th>
                <th>產業V20</th>
                <th>代號</th>
            </tr>
            </thead>
            <tbody className={"bg-light"}>
            {tableRows}
            </tbody>
        </Table>
    )
}

export default TickerTable;
