import { Button, Row, Col, Spinner } from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import Section from '../components/common/Section';
import SectionHeader from '../components/landing/SectionHeader';
import fetch from "cross-fetch";
import TickerTable from './TickerTable';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import bg1 from '../assets/img/back.jpg';


const WatchlistByVolume = ({...args}) => {

  const [isSending, setIsSending] = useState(false);
  const [msg, setMsg] = useState();
  //const [payload, setPayload] = useState(false);
  const [data, setData] = useState(false);
  const [tradeDates, setTradeDates] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
      fetchTradeDates();
  }, [])

  useEffect(() => {
      doFetchTest();
  }, [selectedDate])

  const fetchTradeDates = async () => {
      const url = process.env.REACT_APP_MLSCAN_URL + "/watchlists/tw/trade_dates";
      let response = await fetch(url, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
      }).then(response => {
          if (!response.ok) {
              throw new Error(response.statusText)
          }
          return response.json();
      }).catch(err => {
          console.log(err);
          return false;
      });
      //console.log(response);
      setSelectedDate(response[0]);
      setTradeDates(response);
  }

  /*
  const fetchData = async () => {
        if (isSending) return;
        setIsSending(true);

        const url = process.env.REACT_APP_MLSCAN_URL + "/";
        let response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json();
        }).catch(err => {
            console.log(err);
            return false;
        });
        console.log(response);
        setMsg(response.msg);
        setIsSending(false);
  };
  */

  const doFetchTest = () => {
      if (selectedDate === "") return;
      if (isSending) return;
      setIsSending(true);

      setTimeout(() => {
          fetchTest(selectedDate);
      }, "250")

  }

  const fetchTest = async (tradeDate) => {
        const url = process.env.REACT_APP_MLSCAN_URL + "/watchlists/tw/" + tradeDate;
        let response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json();
        }).catch(err => {
            console.log(err);
            return false;
        });
        let tmp = eval(response.json_data);
        setData(tmp[0]);
        setIsSending(false);
  };

  const buildTables = () => {
      let rows = [];
      for (let i=0; i<33; i++) {
          rows.push(
              <Row key={"industry_"+i} className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType={i} />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>
          )
      }
      return (
          <>
              {rows}
          </>
      )
  }

  function buildDatesMenu(tradeDates) {
      let out = [];
      for (const idx in tradeDates) {
          out.push(<DropdownItem key={"date_"+idx} onClick={() => {setSelectedDate(tradeDates[idx])}}> {tradeDates[idx]} </DropdownItem>);
      }
      return out;
  }

  //let tables = buildTables();

  return (
      <Fragment>

          <Section className="text-center pt-7" image={bg1} position="center bottom" overlay>

              <SectionHeader className={"mt-1 flex-center"}
                  title="台股觀察名單"
                  subtitle="交易量"
              />

              <Row className="flex-center mb-3">

                  <Col xs="4">
                  </Col>

                  <Col xs="4">
                      <Dropdown className="mr-3" isOpen={dropdownOpen} toggle={toggle} direction="down">
                              <DropdownToggle color="primary" caret>{selectedDate}</DropdownToggle>
                              <DropdownMenu {...args} dark>
                                  {tradeDates ? buildDatesMenu(tradeDates) : <></>}
                              </DropdownMenu>
                      </Dropdown>
                  </Col>

                  <Col xs="4">
                      <Button color="primary" href={process.env.REACT_APP_MLSCAN_URL + "/watchlists/download/tw/"+ selectedDate +"/by_volume"} target="_blank" rel="noreferrer" download>
                              下載檔案
                      </Button>
                  </Col>

              </Row>

              <Row className={"mb-4"}>
                  <Col>
                  {isSending? <Spinner color={"primary"}/>: <div></div>}
                  </Col>
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType="> 2000" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType="1000-2000" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType="500-1000" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} binType="200-500" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType="100-200" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType="50-100" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType="25-50" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

              <Row className="flex-center">
                  <Col xs="6" md="3" lg="1" />
                  <Col lg="10">
                      {data ? <TickerTable data={data} rankType="by_volume" binType="0-25" />:<Fragment></Fragment>}
                  </Col>
                  <Col xs="6" md="3" lg="1" />
              </Row>

          </Section>
      </Fragment>
  );
};


export default WatchlistByVolume;
