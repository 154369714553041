import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import MLScanNavbarStandard from './navbar/MLScanNavbarStandard';
import Banner from '../components/landing/Banner';
import Partners from '../components/landing/Partners';
import Processes from '../components/landing/Processes';
import Services from '../components/landing/Services';
import Testimonial from '../components/landing/Testimonial';
import Cta from '../components/landing/Cta';
import FooterStandard from './FooterStandard';
import Watchlist from "./Watchlist";
import WatchlistByIndustry from "./WatchlistByIndustry";
import WatchlistByVolume from "./WatchlistByVolume";


const MainLayout = ({ location, match }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Fragment>
            <MLScanNavbarStandard location={location} />
            {location.pathname == "/by_range" ? <Watchlist />:<></>}
            {location.pathname == "/by_industry" ? <WatchlistByIndustry />:<></>}
            {location.pathname == "/by_volume" ? <WatchlistByVolume />:<></>}
            <FooterStandard />
        </Fragment>
    );
};

MainLayout.propTypes = { location: PropTypes.object.isRequired };

export default MainLayout;
