import classNames from 'classnames';
import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import handleNavbarTransparency from '../../helpers/handleNavbarTransparency';
import MLScanNavbarTopDropDownMenus from './MLScanNavbarTopDropDownMenus';
import LandingRightSideNavItem from './LandingRightSideNavItem';
import { topNavbarBreakpoint } from '../../config';
import AppContext from "../../context/Context";

const MLScanNavbarStandard = ({ location }) => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  const {
    //showBurgerMenu,
    setShowBurgerMenu,
  } = useContext(AppContext);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  // setNavbarCollapsed={setNavbarCollapsed} setShowBurgerMenu={setShowBurgerMenu}
  // <MLScanNavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />

  return (
    <Navbar
      dark
      fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-dark': !navbarCollapsed
      })}
    >
      <Container>
        <NavbarBrand className="text-white" tag={Link} to="/">
          stockscan.tw
        </NavbarBrand>
        <NavbarToggler onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Collapse isOpen={!navbarCollapsed} navbar className="scrollbar">
          <Nav navbar>
            <MLScanNavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} setShowBurgerMenu={setShowBurgerMenu} />
          </Nav>
          <LandingRightSideNavItem location={location} />
        </Collapse>
      </Container>
    </Navbar>
  );

};

export default MLScanNavbarStandard;
