import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody } from 'reactstrap';
import { faChartBar, faFileCode, faLaptopCode } from '@fortawesome/free-solid-svg-icons'


const CardService = ({ media, iconName, title, description, children }) => {
    function getIcon(iconName) {
        if (iconName === "file-code") {
            return faFileCode;
        } else if (iconName === "laptop") {
            return faLaptopCode;
        }
        return faChartBar;
    }

    return (
        <Card className="card-span h-100">
            <div className="card-span-img">
                <FontAwesomeIcon
                    icon={getIcon(iconName)}
                    className={className({ [`text-${media.color}`]: media.color }, media.className)}
                />
            </div>
            <CardBody className="pt-6 pb-4">
                <h3 className="mb-4"><b>{title}</b></h3>
                {description && <p>{description}</p>}
                {children ? children.map((child,index)=> (<>{child}<br/></>)) : <></>}
            </CardBody>
        </Card>
    )
};


CardService.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

export default CardService;
