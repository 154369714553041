import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

//import { toast, ToastContainer } from 'react-toastify';
//import { CloseButton, Fade } from '../components/common/Toast';
//import MyDashboardLayout from './MyDashboardLayout';

import ErrorLayout from '../layouts/ErrorLayout';
import loadable from '@loadable/component';
import Login from './Login';
import Landing from "./Landing";
import MainLayout from "./MainLayout";

//const Landing = loadable(() => import('./Landing'));
const AuthBasicLayout = loadable(() => import('../layouts/AuthBasicLayout'));
const WizardLayout = loadable(() => import('../components/auth/wizard/WizardLayout'));
const AuthCardRoutes = loadable(() => import('../components/auth/card/AuthCardRoutes'));
//const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));
const AuthSplitRoutes = loadable(() => import('../components/auth/split/AuthSplitRoutes'));

const Layout = () => {
  useEffect(() => {
    /*
    AuthBasicLayout.preload();
    Landing.preload();
    WizardLayout.preload();
    AuthCardRoutes.preload();
    AuthSplitRoutes.preload();
    */
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/authentication/basic" component={AuthBasicLayout} />
        <Route path="/authentication/card" component={AuthCardRoutes} />
        <Route path="/authentication/split" component={AuthSplitRoutes} />
        <Route path="/authentication/wizard" component={WizardLayout} />
        <Route path="/errors" component={ErrorLayout} />

        {/*<Route component={MyDashboardLayout} />*/}

        <Route path="/by_range" component={MainLayout} />
        <Route path="/by_industry" component={MainLayout} />
        <Route path="/by_volume" component={MainLayout} />
        <Route path="/" component={Landing} />

        {/*Redirect*/}
        <Redirect to="/errors/404" />
      </Switch>
    </Router>
  );
};

export default Layout;
