import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';
import withRedirect from '../hoc/withRedirect';

const LoginForm = ({ setRedirect, hasLabel, layout }) => {
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSending, setIsSending] = useState(false)

    function dictToURI(dict) {
        var str = [];
        for(var p in dict){
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
        }
        return str.join("&");
    }

    const postData = async (email, password) => {
        if (isSending) return;

        setIsSending(true);

        const url = process.env.REACT_APP_MLSCAN_URL + "/users/token";

        //let input = {username:"admin@test.com", password:"123456"};
        let input = {username:email, password:password};

        //let data = new FormData();
        //data.append('username', 'admin@test.com');
        //data.append('password', '123456');

        let response = await fetch(url,
            {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                body: dictToURI(input)
            }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json();
        }).catch(err => {
            console.log(err);
            return false;
        });

        setIsSending(false);

        if (response && response.access_token) {
            setToken(response.access_token);
        } else {
            console.log("error");
            console.log(response);
        }

    };

    useEffect(() => {
        setIsDisabled(!email || !password);
        if (token !== '') {
            localStorage.setItem('access_token', token);
            setTimeout(()=> {
                console.log('user is logged in');
            }, 1000);
        }
    }, [email, password, token]);

    return !isSending && token ? (<Redirect to={'/by_range'} />) : (
        <Form>
            <FormGroup>
                {hasLabel && <Label>Email</Label>}
                <Input
                    placeholder={!hasLabel ? 'Email address' : ''}
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                    type="email"
                />
            </FormGroup>
            <FormGroup>
                {hasLabel && <Label>密碼</Label>}
                <Input
                    placeholder={!hasLabel ? 'Password' : ''}
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    type="password"
                />
            </FormGroup>
            <Row className="justify-content-between align-items-center">
                <Col xs="auto">
                </Col>
                <Col xs="auto">
                </Col>
            </Row>
            <FormGroup>
                <Button color="primary" block className="mt-3" disabled={isDisabled} onClick={() => {postData(email, password)}}>
                    登入
                </Button>
            </FormGroup>
        </Form>
    );
};

LoginForm.propTypes = {
    setRedirect: PropTypes.func.isRequired,
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'basic',
    hasLabel: false
};

export default withRedirect(LoginForm);
