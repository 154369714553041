import React, { useContext } from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import bg1 from '../../assets/img/generic/bg-1.jpg';
import bg1 from '../assets/img/back_higgs.jpg';

import dashboard from '../assets/img/generic/dashboard-alt-light.png';
//import dashboardDark from '../assets/img/generic/dashboard-alt-dark.png';
import dashboardDark from '../assets/img/site_screenshot_3.png';

import Section from '../components/common/Section';
import AppContext from '../context/Context';

const Banner = () => {
  const { isDark } = useContext(AppContext);

  return (
    <Section className="py-0 overflow-hidden" image={bg1} position="center bottom" overlay>
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col md={11} lg={8} xl={4} className="pb-7 pb-xl-9 text-center text-xl-left">
          <Button tag={Link} color="outline-danger" className="mb-4 fs--1 border-2x rounded-pill" to="/login">
            瞭解更多
          </Button>
          <h1 className="text-white font-weight-light">
            快速得到
            <Typed
              strings={['分析', '市場信息', '價格', '訊號']}
              typeSpeed={40}
              backSpeed={50}
              className="font-weight-bold pl-2"
              loop
            />
            <br />
            客製化的解決方案
          </h1>
          <p className="lead text-white opacity-75">
            我們可以根據您的需求整理並分析所需的數據<br/>
            讓您有更多的時間與精力放在更重要的交易上
          </p>
          <Link className="btn btn-outline-light border-2x rounded-pill btn-lg mt-4 fs-0 py-2" to="/login">
            客戶登入
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Link>
        </Col>
        <Col xl={{ size: 7, offset: 1 }} className="align-self-end">
          <Link to="/" className="img-landing-banner">
            <img className="img-fluid" src={isDark ? dashboardDark : dashboard} alt="" />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Banner;
