import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
/*
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownComponents from './NavbarDropdownComponents';
import {
    homeRoutes,
    blogRoutes,
    flashcardsRoutes,
    googleGCPRoutes,
  authenticationRoutes,
  chatRoutes,
  componentRoutes,
  ECommerceRoutes,
  emailRoutes,
  pageRoutes,
  pluginRoutes,
  utilityRoutes,
  widgetsRoutes,
  kanbanRoutes,
  calenderRoutes
} from '../../csflashcard_routes';
 */
import { breakpoints, /*getPageName*/ } from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

    //const blogs = [blogRoutes];
    //const gcpProjects = [googleGCPRoutes];
    //const flashcards = [flashcardsRoutes];

  //const components = [componentRoutes, pluginRoutes, utilityRoutes, flashcardsRoutes];
  //const pages = [pageRoutes, calenderRoutes, kanbanRoutes, widgetsRoutes, chatRoutes, emailRoutes, ECommerceRoutes];
  //const pages = [widgetsRoutes];

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };

  //const isLanding = getPageName('landing');
  return (
    <>
      {/*
      <NavbarDropdown
        title={homeRoutes.name}
        items={homeRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      */}

      {/*
      <NavbarDropdown
        title={flashcardsRoutes.name}
        items={[flashcardsRoutes]}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      */}

      {/*
      <NavbarDropdown title={pageRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      */}

      {/*
      <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/top">
            Projects
          </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to={{pathname: "https://jpflashcard.com"}} target="_blank">
            jpflashcard.com
          </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/tensorflow">
            Tensorflow
          </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/ml">
            Machine Learning
          </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/aws">
            Amazon AWS
          </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/gcp">
            Google Cloud
          </NavLink>
      </NavItem>

      <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/quant">
            Quantitative Trading
          </NavLink>
      </NavItem>
      */}

      {/*
      <NavbarDropdownComponents
        title={blogRoutes.name}
        items={blogs}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      <NavbarDropdownComponents
        title={googleGCPRoutes.name}
        items={gcpProjects}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      <NavbarDropdownComponents
        title={flashcardsRoutes.name}
        items={flashcards}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      */}

    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
